<template>
    <div class="Stations full-height pa-4 d-flex flex-column ">
        <v-col class="full-height content-area pa-0">
            <v-row class="full-height">
                <v-col cols="6" class="station pa-0 pr-2 pb-2 d-flex flex-column">
                    <div class="addOverlay-container pr-2 pb-2" v-if="stations[0].connected === 'noStation'">
                        <v-btn class="addOverlay" elevation="0" @click="addStation(1)" :disabled="!checkUserRights('systemStationsEdit')">
                            <p class="icon"></p>
                        </v-btn>
                    </div>
                    <div class="title-bar d-flex pa-4 align-center flex-grow-0" v-if="stations[0].connected != 'noStation'">
                        <p>Station 1</p>
                    </div>
                    <div class="stationInfo d-flex flex-column justify-center align-center flex-grow-1" v-if="stations[0].connected == 'connected'" >
                        <div class="stationStatus connected">
                            <p>{{ $t('system.stationsSettings.connected') }}</p>
                        </div>
                        <v-btn class="content-btn small mt-6" elevation="0" @click="disconnectStation(1)" :disabled="!checkUserRights('systemStationsEdit')">
                            {{ $t('system.stationsSettings.disconnect') }}
                        </v-btn>
                    </div>
                    <div class="stationInfo d-flex flex-column justify-center align-center flex-grow-1" v-if="stations[0].connected === 'disconnected'">
                        <div class="stationStatus">
                            <p>{{ $t('system.stationsSettings.disconnected') }}</p>
                        </div>
                        <div class="d-flex">
                            <v-btn class="content-btn small mt-6 mr-8" elevation="0" @click="reconnectStation(1)" :disabled="!checkUserRights('systemStationsEdit')">
                                {{ $t('system.stationsSettings.reconnect') }}
                            </v-btn>
                            <v-btn class="content-btn small mt-6" elevation="0" @click="removeStation(1)" :disabled="!checkUserRights('systemStationsEdit')">
                                {{ $t('system.stationsSettings.removeStation') }}
                            </v-btn>
                        </div>
                    </div>
                </v-col>
                <v-col cols="6" class="station pa-0 pl-2 pb-2 d-flex flex-column">
                    <div class="addOverlay-container pl-2 pb-2" v-if="stations[1].connected === 'noStation'">
                        <v-btn class="addOverlay" elevation="0" @click="addStation(2)" :disabled="!checkUserRights('systemStationsEdit')">
                            <p class="icon"></p>
                        </v-btn>
                    </div>
                    <div class="title-bar d-flex pa-4 align-center flex-grow-0" v-if="stations[1].connected != 'noStation'">
                        <p>Station 2</p>
                    </div>
                    <div class="stationInfo d-flex flex-column justify-center align-center flex-grow-1" v-if="stations[1].connected === 'connected'" >
                        <div class="stationStatus connected">
                            <p>{{ $t('system.stationsSettings.connected') }}</p>
                        </div>
                        <v-btn class="content-btn small mt-6" elevation="0" @click="disconnectStation(2)" :disabled="!checkUserRights('systemStationsEdit')">
                            {{ $t('system.stationsSettings.disconnect') }}
                        </v-btn>
                    </div>
                    <div class="stationInfo d-flex flex-column justify-center align-center flex-grow-1" v-if="stations[1].connected === 'disconnected'">
                        <div class="stationStatus">
                            <p>{{ $t('system.stationsSettings.disconnected') }}</p>
                        </div>
                        <div class="d-flex">
                            <v-btn class="content-btn small mt-6 mr-8" elevation="0" @click="reconnectStation(2)" :disabled="!checkUserRights('systemStationsEdit')">
                                {{ $t('system.stationsSettings.reconnect') }}
                            </v-btn>
                            <v-btn class="content-btn small mt-6" elevation="0" @click="removeStation(2)" :disabled="!checkUserRights('systemStationsEdit')">
                                {{ $t('system.stationsSettings.removeStation') }}
                            </v-btn>
                        </div>
                    </div>
                </v-col>
                <v-col cols="6" class="station pa-0 pr-2 pt-2 d-flex flex-column">
                    <div class="addOverlay-container pr-2 pt-2"  v-if="stations[2].connected === 'noStation'">
                        <v-btn class="addOverlay" elevation="0" @click="addStation(3)" :disabled="!checkUserRights('systemStationsEdit')">
                            <p class="icon"></p>
                        </v-btn>
                    </div>
                    <div class="title-bar d-flex pa-4 align-center flex-grow-0" v-if="stations[2].connected != 'noStation'">
                        <p>Station 3</p>
                    </div>
                    <div class="stationInfo d-flex flex-column justify-center align-center flex-grow-1" v-if="stations[2].connected === 'connected'" >
                        <div class="stationStatus connected">
                            <p>{{ $t('system.stationsSettings.connected') }}</p>
                        </div>
                        <v-btn class="content-btn small mt-6" elevation="0" @click="disconnectStation(3)" :disabled="!checkUserRights('systemStationsEdit')">
                            {{ $t('system.stationsSettings.disconnect') }}
                        </v-btn>
                    </div>
                    <div class="stationInfo d-flex flex-column justify-center align-center flex-grow-1" v-if="stations[2].connected === 'disconnected'">
                        <div class="stationStatus">
                            <p>{{ $t('system.stationsSettings.disconnected') }}</p>
                        </div>
                        <div class="d-flex">
                            <v-btn class="content-btn small mt-6 mr-8" elevation="0" @click="reconnectStation(3)" :disabled="!checkUserRights('systemStationsEdit')">
                                {{ $t('system.stationsSettings.reconnect') }}
                            </v-btn>
                            <v-btn class="content-btn small mt-6" elevation="0" @click="removeStation(3)" :disabled="!checkUserRights('systemStationsEdit')">
                                {{ $t('system.stationsSettings.removeStation') }}
                            </v-btn>
                        </div>
                    </div>
                </v-col>
                <v-col cols="6" class="station pa-0 pl-2 pt-2 d-flex flex-column">
                    <div class="addOverlay-container pl-2 pt-2" v-if="stations[3].connected === 'noStation'">
                        <v-btn class="addOverlay" elevation="0" @click="addStation(4)" :disabled="!checkUserRights('systemStationsEdit')">
                            <p class="icon"></p>
                        </v-btn>
                    </div>
                    <div class="title-bar d-flex pa-4 align-center flex-grow-0" v-if="stations[3].connected != 'noStation'">
                        <p>Station 4</p>
                    </div>
                    <div class="stationInfo d-flex flex-column justify-center align-center flex-grow-1" v-if="stations[3].connected === 'connected'">
                        <div class="stationStatus connected">
                            <p>{{ $t('system.stationsSettings.connected') }}</p>
                        </div>
                        <v-btn class="content-btn small mt-6" elevation="0" @click="disconnectStation(4)" :disabled="!checkUserRights('systemStationsEdit')">
                            {{ $t('system.stationsSettings.disconnect') }}
                        </v-btn>
                    </div>
                    <div class="stationInfo d-flex flex-column justify-center align-center flex-grow-1" v-if="stations[3].connected === 'disconnected'">
                        <div class="stationStatus">
                            <p>{{ $t('system.stationsSettings.disconnected') }}</p>
                        </div>
                        <div class="d-flex">
                            <v-btn class="content-btn small mt-6 mr-8" elevation="0" @click="reconnectStation(4)" :disabled="!checkUserRights('systemStationsEdit')">
                                {{ $t('system.stationsSettings.reconnect') }}
                            </v-btn>
                            <v-btn class="content-btn small mt-6" elevation="0" @click="removeStation(4)" :disabled="!checkUserRights('systemStationsEdit')">
                                {{ $t('system.stationsSettings.removeStation') }}
                            </v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-col>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button buttonFunction="back" @footerButtonClick="goToSystem"></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'

import {mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'Stations',
    components: {
        FooterButton,
    },
    props: {
    },
    data () {
        return {
        }
    },
    computed: {
        ...mapState([
            'stations',
            'activeStationId',
        ]),
    },
    methods:{
        goToSystem(){
            this.$router.push('system');
        },
        addStation(stationNr){
            this.$store.dispatch('postAxiosNoSetter', ['/admin/stations/add', {'id': stationNr}])
            .then(response=>{
                if(response.status === 200){
                    this.$store.dispatch('getAxiosSetter', ['/disi/station/status', 'stations']);
                }
            })
        },
        disconnectStation(stationNr){
            this.$store.dispatch('postAxiosNoSetter', ['/admin/stations/disconnect', {'id': stationNr}])
            .then(response=>{
                if(response.status === 200){
                    this.$store.dispatch('getAxiosSetter', ['/disi/station/status', 'stations']);
                }
            })
        },
        reconnectStation(stationNr){
            this.$store.dispatch('postAxiosNoSetter', ['/admin/stations/reconnect', {'id': stationNr}])
            .then(response=>{
                if(response.status === 200){
                    this.$store.dispatch('getAxiosSetter', ['/disi/station/status', 'stations']);
                }
            })
        },
        removeStation(stationNr){
            this.$confirm({message: this.$t('alerts.removeStation'), show: true, cancelText: this.$t('general.cancel'), continueText: this.$t('general.continue') })
                .then(() => {
                    // continue
                    this.$store.dispatch('postAxiosNoSetter', ['/admin/stations/remove', {'id': stationNr}])
                        .then(response=>{
                            if(response.status === 200){
                                this.$store.dispatch('getAxiosSetter', ['/disi/station/status', 'stations']);
                            }
                        })
                })
                .catch(() => {
                    // cancel
                });
        },
    },
    mixins: [mixins],
    created() {
        this.$store.dispatch('getAxiosSetter', ['/disi/station/status', 'stations']);
    },
}
</script>
<style scoped lang="scss">
.station{
    height: 50%;
    position: relative;

    .stationInfo{
        background-color: white;
    }

    .addOverlay-container{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .theme--light.v-btn.addOverlay{
        width: 100%;
        height: 100%;
        background-color: $disiBluegreyMedium;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon::before{
            content: "";
            display: block;
            width: 50px;
            height: 50px;
            background-image: url("../assets/new_irisblue.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
        &.v-btn--disabled .icon::before{
            background-image: none;
        }
    }

}
.title-bar {
    background-color: $disiDarkblueMedium;
    height: 60px;

    p {
        color: white;
        font-weight: 500;
    }
}
.stationStatus{
    display: flex;
    align-items: center;
    &::before{
        content: "";
        display: inline-block;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        margin-right: 16px;
        margin-bottom: 2px;
        background-color: $disiRed;
    }
    &.connected::before{
        background-color: $disiIrisblue;
    }
}

</style>